define('m09/controllers/durchbruch', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {
    debug: true,
    openFromFile: false,

    aTyp: 1,
    displayErrors: true,
    oeffnungsform: true,
    kreis: false,
    lichterabstanddisabled: true,
    durchbruchhoehedisabled: true,
    lichterabstand: "",
    durchbruchhoehe: "",
    traegerhoehe: "",
    vorholzlaenge: "",
    abstandauflager: "",
    resthoeheoben: "",
    resthoeheunten: "",
    durchbruchlaenge: "",

    durchbruchInitialized: false,

    ttVorholzlaenge: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + "0.0 - 999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttAbstandauflager: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + "0.0 - 999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    // ttLichterabstand: Ember.computed('i18n.locale', {
    //
    //   get(key) {
    //     return this.get('i18n').t('wertebereich') + "0.0 - 999.9 [cm]";
    //   },
    //   set(key, value) {
    //     return value;
    //   }
    //
    // }),
    ttResthoeheoben: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + "0.0 - 999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttResthoeheunten: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + "0.0 - 999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttDurchbruchhoehe: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + "5.0 - 999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),
    ttDurchbruchlaenge: Ember['default'].computed('i18n.locale', {

      get: function get(key) {
        return this.get('i18n').t('wertebereich') + "5.0 - 999.9 [cm]";
      },
      set: function set(key, value) {
        return value;
      }

    }),

    i18n: Ember['default'].inject.service(),

    validations: {
      vorholzlaenge: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.0
        }
      },
      abstandauflager: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.0
        }
      },
      lichterabstand: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      resthoeheoben: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      resthoeheunten: {
        numericality: {
          greaterThanOrEqualTo: 0.0,
          lessThanOrEqualTo: 999.0
        }
      },
      durchbruchhoehe: {
        numericality: {
          greaterThanOrEqualTo: 5.0,
          lessThanOrEqualTo: 5.4
        }
      },
      durchbruchlaenge: {
        numericality: {
          greaterThanOrEqualTo: 5.0,
          lessThanOrEqualTo: 999.0
        }
      }
    },

    contentOeffnungsformen: [],
    oeffnungsformen: [{
      name: "dbf0",
      id: true
    }, {
      name: "dbf1",
      id: false
    }],

    init: function init() {

      this._super();
      var self = this;

      this.set('ttVorholzlaenge', self.get('i18n').t('wertebereich') + " 0.0 - 999.9 [cm]");
      this.set('ttAbstandauflager', self.get('i18n').t('wertebereich') + " 0.0 - 999.9 [cm]");
      this.set('ttResthoeheoben', self.get('i18n').t('wertebereich') + " 0.0 - 999.9 [cm]");
      this.set('ttResthoeheunten', self.get('i18n').t('wertebereich') + " 0.0 - 999.9 [cm]");
      this.set('ttDurchbruchlaenge', self.get('i18n').t('wertebereich') + " 5.0 - 999.9 [cm]");

      this.get('traegerhoehe', "0.0");
      this.set('lichterabstand', "0.0");
      this.set('durchbruchhoehe', "");
      this.set('vorholzlaenge', "");

      this.set('durchbruchInitialized', true);

      this.setSelectFieldsContent();
    },

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in durchbruch: ");
        console.log(values);
      }

      this.set('openFromFile', true);

      this.set('oeffnungsform', values.RechteckForm);

      this.set('vorholzlaenge', values.L_V);
      this.set('abstandauflager', values.L_A);
      this.set('resthoeheoben', values.h_ro);
      this.set('resthoeheunten', values.h_ru);
      this.set('durchbruchhoehe', values.h_d);

      this.set('durchbruchlaenge', values.a);

      var vorholzlaengeEvent = {
        target: {
          name: "vorholzlaenge"
        }
      };
      var abstandauflagerEvent = {
        target: {
          name: "abstandauflager"
        }
      };
      var resthoeheobenEvent = {
        target: {
          name: "resthoeheoben"
        }
      };
      var resthoeheuntenEvent = {
        target: {
          name: "resthoeheunten"
        }
      };
      var durchbruchhoeheEvent = {
        target: {
          name: "durchbruchhoehe"
        }
      };
      var durchbruchlaengeEvent = {
        target: {
          name: "durchbruchlaenge"
        }
      };

      this.setX3D(Number(values.L_V), vorholzlaengeEvent);
      this.setX3D(Number(values.L_A), abstandauflagerEvent);
      this.setX3D(Number(values.h_ro), resthoeheobenEvent);
      this.setX3D(Number(values.h_ru), resthoeheuntenEvent);
      this.setX3D(Number(values.h_d), durchbruchhoeheEvent);

      this.send('validation', -1, { target: { name: "foo" } });

      this.setX3D(Number(values.a), durchbruchlaengeEvent);

      this.set('openFromFile', false);
    },

    setSelectFieldsContent: function setSelectFieldsContent() {
      var self = this;
      self.setContentOeffnungsformen();
    },

    setContentOeffnungsformen: function setContentOeffnungsformen() {
      var self = this;
      var indices = [true, false];
      self.set('contentOeffnungsformen', self.getSelectFieldContent('contentOeffnungsformen', indices));
    },

    getSelectFieldContent: function getSelectFieldContent(db, indexes) {
      var self = this;
      var tmp = [];
      var dbContent = self.getDBContent(db);
      for (var i = 0; i < indexes.length; i++) {
        for (var k = 0; k < dbContent.length; k++) {
          if (dbContent[k].id === indexes[i]) {
            tmp.push({ id: dbContent[k].id, name: this.get('i18n').t(dbContent[k].name), value: dbContent[k].value, disabled: dbContent[k].disabled });
          }
        }
      }

      return tmp;
    },

    getDBContent: function getDBContent(db) {
      var dbContent = [];
      switch (db) {

        case 'contentOeffnungsformen':
          dbContent = this.get('oeffnungsformen');
          break;
      }
      return dbContent;
    },

    getValueFromSelectField: function getValueFromSelectField(contentArray, index) {
      var self = this;

      var tmp = "";
      var len = contentArray.length;

      for (var i = 0; i < len; i++) {
        if (contentArray[i].id === index) {
          tmp = contentArray[i].name;
        }
      }
      return tmp;
    },

    watchOeffnungsform: (function () {

      var self = this;

      var durchbruch = self.controllerFor('application').get('model').durchbruch.objectAt(0);
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);

      if (!self.get('oeffnungsform')) {
        self.set('kreis', true);
        self.set('durchbruchlaenge', String(self.get('durchbruchhoehe')));
      } else {
        self.set('kreis', false);
        self.set('durchbruchlaenge', '');
        x3d.set('durchbruchlaengeEingetragen', false);
      }

      self.set('openFromFile', true);
      self.send('validation');
      self.set('openFromFile', false);

      durchbruch.set('RechteckForm', self.get('oeffnungsform'));

      this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', 'traeger').get('durchbr').objectAt(0).set('istKreis', !self.get('oeffnungsform'));
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('transformHelper', !this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('transformHelper'));
    }).observes('oeffnungsform'),

    // ---- grenzwerte lichter abstand ----

    // watchVorholzlaenge passt die grenzwerte des lichten abstands an. ist aber auskommentiert, weil lichter abstand momentan deaktiviert ist.
    // wenn der code aktiv wird fuehrt das dazu, dass die validierung in durchbruch fehlschlaegt weil 0.0 dann kein zulaessiger wert mehr ist.

    // watchVorholzlaenge: function() {
    //   var self = this;

    //   var traeger = self.controllerFor('traeger');
    //   var hoehe = parseFloat(traeger.get('hthoehe'));
    //   var lV = parseFloat(self.get('vorholzlaenge')).toFixed(1);

    //   console.log(hoehe);
    //   console.log(lV);

    //   var tmp = Math.max(1.5 * hoehe, lV).toFixed(1);

    //   console.log(tmp);

    //   self.set('validations.lichterabstand.numericality.greaterThanOrEqualTo', tmp);
    //   self.set('ttLichterabstand', self.get('i18n').t('wertebereich') + tmp + " - 999.9 [cm]");
    // }.observes('vorholzlaenge'),

    // ---- grenzwerte lichter abstand ----

    watchNumericalInputs: (function () {

      var self = this;

      if (!self.openFromFile) {

        if (self.get('vorholzlaenge').indexOf(",") !== -1) {
          self.set('vorholzlaenge', self.get('vorholzlaenge').replace(",", "."));
        }

        if (self.get('abstandauflager').indexOf(",") !== -1) {
          self.set('abstandauflager', self.get('abstandauflager').replace(",", "."));
        }

        if (self.get('lichterabstand').indexOf(",") !== -1) {
          self.set('lichterabstand', self.get('lichterabstand').replace(",", "."));
        }

        if (self.get('resthoeheoben').indexOf(",") !== -1) {
          self.set('resthoeheoben', self.get('resthoeheoben').replace(",", "."));
        }

        if (self.get('resthoeheunten').indexOf(",") !== -1) {
          self.set('resthoeheunten', self.get('resthoeheunten').replace(",", "."));
        }

        // if (self.get('durchbruchhoehe').indexOf(",") !== -1) {
        //   self.set('durchbruchhoehe', self.get('durchbruchhoehe').replace(",", "."));
        // }

        if (self.get('durchbruchlaenge').indexOf(",") !== -1) {
          self.set('durchbruchlaenge', self.get('durchbruchlaenge').replace(",", "."));
        }
      }
    }).observes('vorholzlaenge', 'abstandauflager', 'lichterabstand', 'resthoeheoben', 'resthoeheunten', 'durchbruchlaenge'),

    setX3D: function setX3D(value, event) {
      var self = this;

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      application.get('model').x3ddefault.objectAt(0).set('ergebnisGeladen', false);
      application.get('model').x3ddefault.objectAt(0).set('aktuellerSchraubenTyp', 'standardSchraube');
      application.get('model').x3ddefault.objectAt(0).set('schraubenlisteAktualisiert', !application.get('model').x3ddefault.objectAt(0).get('schraubenlisteAktualisiert'));

      value = parseFloat(value).toFixed(1);

      switch (event.target.name) {
        case 'vorholzlaenge':
          if (this.errors.vorholzlaenge.length === 0) {
            bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).set('vorholzlaenge', value);
            application.get('model').x3ddefault.objectAt(0).set('vorholzlaengeEingetragen', true);
            var aal = bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('abstandauflager');
            bauteile.findBy('id', 'auflager').get('translations').objectAt(0).set('x', value - aal);
            this.setTraegerlaenge();

            self.set('validations.abstandauflager.numericality.lessThanOrEqualTo', Number(value));
            self.set('ttAbstandauflager', self.get('i18n').t('wertebereich') + " " + self.get('validations.abstandauflager.numericality.greaterThanOrEqualTo') + " - " + Number(value).toFixed(1) + " [cm]");

            console.log('Grenzwerte Auflagerabstand:', self.get('ttAbstandauflager'));
          } else {
            application.get('model').x3ddefault.objectAt(0).set('vorholzlaengeEingetragen', false);
          }
          break;
        case 'abstandauflager':
          if (this.errors.abstandauflager.length === 0) {
            var vhl = bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge');
            bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).set('abstandauflager', value);
            bauteile.findBy('id', 'auflager').get('translations').objectAt(0).set('x', vhl - value);
            application.get('model').x3ddefault.objectAt(0).set('abstandauflagerEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('abstandauflagerEingetragen', false);
          }
          break;
        case 'lichterabstand':
          if (this.errors.lichterabstand.length === 0) {

            application.get('model').x3ddefault.objectAt(0).set('lichterabstandEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('lichterabstandEingetragen', false);
          }
          break;
        case 'resthoeheoben':
          if (this.errors.resthoeheoben.length === 0) {
            bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).set('resthoeheoben', value);

            self.setGrenzwerteResthoeheUnten();
            self.setGrenzwerteDurchbruchHoehe();
            self.setDurchbruchHoehe();
            self.setGrenzwerteDurchbruchLaenge();

            application.get('model').x3ddefault.objectAt(0).set('resthoeheobenEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('resthoeheobenEingetragen', false);
          }
          break;
        case 'resthoeheunten':
          if (this.errors.resthoeheunten.length === 0) {
            bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).set('resthoeheunten', value);

            self.setGrenzwerteResthoeheOben();
            self.setGrenzwerteDurchbruchHoehe();
            self.setDurchbruchHoehe();
            self.setGrenzwerteDurchbruchLaenge();

            application.get('model').x3ddefault.objectAt(0).set('resthoeheuntenEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('resthoeheuntenEingetragen', false);
          }
          break;
        case 'durchbruchhoehe':
          if (this.errors.durchbruchhoehe.length === 0) {

            application.get('model').x3ddefault.objectAt(0).set('durchbruchhoeheEingetragen', true);
          } else {
            application.get('model').x3ddefault.objectAt(0).set('durchbruchhoeheEingetragen', false);
          }
          break;
        case 'durchbruchlaenge':
          if (self.errors.durchbruchlaenge.length === 0) {
            bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).set('durchbruchlaenge', value);
            application.get('model').x3ddefault.objectAt(0).set('durchbruchlaengeEingetragen', true);
            self.setGrenzwerteDurchbruchHoehe();
            self.setTraegerlaenge();
            self.setGrenzwerteResthoeheOben();
            self.setGrenzwerteResthoeheUnten();
          } else {
            application.get('model').x3ddefault.objectAt(0).set('durchbruchlaengeEingetragen', false);
          }
          break;

      }
      this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('transformHelper', !application.get('model').x3ddefault.objectAt(0).get('transformHelper'));
      self.controllerFor('application').zentriertObjekt();
    },

    setGrenzwerteResthoeheOben: function setGrenzwerteResthoeheOben() {
      var self = this;

      var traeger = this.controllerFor('traeger');

      var min = 0.0,
          max = 999.0;
      var h_ru = self.get('resthoeheunten');
      var h = traeger.get('hthoehe');
      var hd_min = self.get('validations.durchbruchhoehe.numericality.greaterThanOrEqualTo');

      if (h_ru === "" && h !== "") {
        min = 0.25 * Number(h);
        h_ru = 0;
      } else if (h_ru !== "" && h !== "") {
        min = Math.max(0.25 * Number(h), 0.7 * Number(h) - Number(h_ru));
      }

      max = Number(h) - Number(hd_min) - Math.max(0.25 * Number(h), h_ru);

      self.set('validations.resthoeheoben.numericality.greaterThanOrEqualTo', Number(min.toFixed(1)));
      self.set('validations.resthoeheoben.numericality.lessThanOrEqualTo', Number(max.toFixed(1)));
      self.set('ttResthoeheoben', self.get('i18n').t('wertebereich') + min.toFixed(1) + " - " + max.toFixed(1) + " [cm]");
    },

    setGrenzwerteResthoeheUnten: function setGrenzwerteResthoeheUnten() {
      var self = this;

      var traeger = this.controllerFor('traeger');

      var min = 0.0,
          max = 999.0;
      var h_ro = self.get('resthoeheoben');
      var h = traeger.get('hthoehe');
      var hd_min = self.get('validations.durchbruchhoehe.numericality.greaterThanOrEqualTo');

      if (h_ro === "" && h !== "") {
        min = 0.25 * Number(h);
        h_ro = 0;
      } else if (h_ro !== "" && h !== "") {
        min = Math.max(0.25 * Number(h), 0.7 * Number(h) - Number(h_ro));
      }

      max = Number(h) - Number(hd_min) - Math.max(0.25 * Number(h), h_ro);

      self.set('validations.resthoeheunten.numericality.greaterThanOrEqualTo', Number(min.toFixed(1)));
      self.set('validations.resthoeheunten.numericality.lessThanOrEqualTo', Number(max.toFixed(1)));
      self.set('ttResthoeheunten', self.get('i18n').t('wertebereich') + min.toFixed(1) + " - " + max.toFixed(1) + " [cm]");
    },

    setTraegerlaenge: function setTraegerlaenge() {

      var self = this;

      var application = this.controllerFor('application');
      var x3d = application.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      var vorholzlaenge = Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge'));
      var durchbruchlaenge = Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('durchbruchlaenge'));
      var abstandauflager = Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('abstandauflager'));

      var neueTraegerlaenge = vorholzlaenge + durchbruchlaenge + abstandauflager;

      bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).set('x', neueTraegerlaenge);
    },

    setGrenzwerteDurchbruchLaenge: function setGrenzwerteDurchbruchLaenge() {
      var self = this;

      var traeger = this.controllerFor('traeger');
      var min = 0.0,
          max = self.get('validations.durchbruchlaenge.numericality.lessThanOrEqualTo');
      var hd = self.get('durchbruchhoehe');
      var h = traeger.get('hthoehe');

      if (h !== "") {
        min = Math.max(Number(h) * 0.1, 5);
      } else {
        min = 5;
      }

      if (String(hd) === "0.0") {
        hd = "5";
      }

      if (String(h) === "") {
        max = Number(hd) * 2.5;
      } else {
        max = Math.min(Number(hd) * 2.5, h);
      }

      self.set('validations.durchbruchlaenge.numericality.greaterThanOrEqualTo', Number(min.toFixed(1)));
      self.set('validations.durchbruchlaenge.numericality.lessThanOrEqualTo', Number(max.toFixed(1)));
      self.set('ttDurchbruchlaenge', self.get('i18n').t('wertebereich') + min.toFixed(1) + " - " + max.toFixed(1) + " [cm]");
    },

    setGrenzwerteDurchbruchHoehe: function setGrenzwerteDurchbruchHoehe() {
      var self = this;

      var traeger = this.controllerFor('traeger');
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);
      var min = 0.0,
          max = 999.0;
      var a = self.get('durchbruchlaenge');
      var h = traeger.get('hthoehe');
      var hro = x3d.get('bauteile').findBy('id', 'traeger').get('durchbr').objectAt(0).get('resthoeheoben');
      var hru = x3d.get('bauteile').findBy('id', 'traeger').get('durchbr').objectAt(0).get('resthoeheunten');

      if (a !== "") {
        min = Math.max(Number(a) / 2.5, 5);
      } else {
        min = 5;
      }

      if (String(h) !== "") {
        max = 0.3 * Number(h);
      }

      self.set('validations.durchbruchhoehe.numericality.greaterThanOrEqualTo', Number(min.toFixed(1)));
      self.set('validations.durchbruchhoehe.numericality.lessThanOrEqualTo', Number(max.toFixed(1)));
      self.set('ttDurchbruchhoehe', self.get('i18n').t('wertebereich') + min.toFixed(1) + " - " + max.toFixed(1) + " [cm]");
    },

    setDurchbruchHoehe: function setDurchbruchHoehe() {
      var self = this;

      var traeger = this.controllerFor('traeger');
      var x3d = self.controllerFor('application').get('model').x3ddefault.objectAt(0);

      var h = traeger.get('hthoehe');
      var hro = x3d.get('bauteile').findBy('id', 'traeger').get('durchbr').objectAt(0).get('resthoeheoben');
      var hru = x3d.get('bauteile').findBy('id', 'traeger').get('durchbr').objectAt(0).get('resthoeheunten');

      var dbhoehe = h - hro - hru;

      x3d.get('bauteile').findBy('id', 'traeger').get('durchbr').objectAt(0).set('d', dbhoehe.toFixed(1));

      if (String(h) !== "" && self.get('resthoeheoben') !== "" && self.get('resthoeheunten') !== "") {
        self.set('durchbruchhoehe', String(dbhoehe.toFixed(1)));
        if (self.get('oeffnungsform') === false) {
          self.set('durchbruchlaenge', self.get('durchbruchhoehe'));
        }
      } else {
        self.set('durchbruchhoehe', "0.0");
      }
    },

    setGrenzwerteLageDurchbruch: function setGrenzwerteLageDurchbruch() {
      var self = this;

      var traeger = this.controllerFor('traeger');
      var h = traeger.get('hthoehe');
      var h_min = traeger.get('validations.hthoehe.numericality.greaterThanOrEqualTo');

      var vorhozlaenge_min = Math.max(Number(h_min), Number(h));
      var auflagerabstand_min = Math.max(Number(h_min), Number(h)) / 2;

      self.set('validations.vorholzlaenge.numericality.greaterThanOrEqualTo', Number(vorhozlaenge_min));
      self.set('ttVorholzlaenge', self.get('i18n').t('wertebereich') + vorhozlaenge_min.toFixed(1) + " - 999.9 [cm]");

      self.set('validations.abstandauflager.numericality.greaterThanOrEqualTo', Number(auflagerabstand_min));
      self.set('ttAbstandauflager', self.get('i18n').t('wertebereich') + auflagerabstand_min.toFixed(1) + " - 999.9 [cm]");
    },

    setAbmessungenDurchbruchToNull: function setAbmessungenDurchbruchToNull() {
      var self = this;

      self.set('durchbruchlaenge', "");
      self.set('durchbruchhoehe', "");
      self.set('resthoeheoben', "");
      self.set('resthoeheunten', "");

      var x3d = this.controllerFor('application').get('model').x3ddefault.objectAt(0);

      x3d.set('resthoeheobenEingetragen', false);
      x3d.set('resthoeheuntenEingetragen', false);
      x3d.set('durchbruchlaengeEingetragen', false);

      var traeger = this.controllerFor('traeger');
      var h = traeger.get('hthoehe');
      var value = Number(h) / 3;

      var bauteile = x3d.get('bauteile');

      bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).set('durchbruchlaenge', value);
      bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).set('resthoeheoben', value);
      bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).set('resthoeheunten', value);
      bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).set('durchbruchhoehe', value);
    },

    actions: {
      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          this.setX3D(value, event);
        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('durchbruchInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var durchbruch = self.controllerFor('application').get('model').durchbruch.objectAt(0);
            durchbruch.set('L_V', parseFloat(self.get('vorholzlaenge')).toFixed(1));
            durchbruch.set('L_A', parseFloat(self.get('abstandauflager')).toFixed(1));
            durchbruch.set('h_ro', parseFloat(self.get('resthoeheoben')).toFixed(1));
            durchbruch.set('h_ru', parseFloat(self.get('resthoeheunten')).toFixed(1));
            durchbruch.set('h_d', parseFloat(self.get('durchbruchhoehe')).toFixed(1));

            // var traeger = self.controllerFor('traeger');
            // var hoehe = parseFloat(traeger.get('hthoehe'));
            // var lV = parseFloat(self.get('vorholzlaenge')).toFixed(1);

            // console.log(hoehe);
            // console.log(lV);

            // var tmp = Math.max(1.5 * hoehe, lV);

            // console.log(tmp);

            // self.set('validations.lichterabstand.numericality.greaterThanOrEqualTo', tmp);
            // self.set('ttLichterabstand', self.get('i18n').t('wertebereich') + tmp + " - 999.9 [cm]");

            if (self.get('kreis')) {
              durchbruch.set('a', parseFloat(self.get('durchbruchhoehe')).toFixed(1));
            } else {
              durchbruch.set('a', parseFloat(self.get('durchbruchlaenge')).toFixed(1));
            }
          } else {
            self.set('displayErrors', true);
            application.set('durchbruchInvalid', true);
          }
        });
      },

      vorholzlaengeIsSelected: function vorholzlaengeIsSelected() {
        var vorholzlaengeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('vorholzlaengeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('vorholzlaengeAktiv', !vorholzlaengeAktiv);

        if (this.get('vorholzlaenge') !== "") {
          var vorholzlaengework = parseFloat(this.get('vorholzlaenge').replace(",", "."));
          var vorholzlaengerounded = vorholzlaengework.toFixed(1);
          this.set('vorholzlaenge', vorholzlaengerounded);
          this.send('validation', vorholzlaengerounded, {
            target: {
              name: "vorholzlaenge"
            }
          });
        }

        if (vorholzlaengeAktiv === false) {
          document.getElementsByName('vorholzlaenge')[0].setSelectionRange(0, this.get('vorholzlaenge').length);
        }
      },

      abstandauflagerIsSelected: function abstandauflagerIsSelected() {
        var abstandauflagerAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('abstandauflagerAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('abstandauflagerAktiv', !abstandauflagerAktiv);

        if (this.get('abstandauflager') !== "") {
          var abstandauflagerwork = parseFloat(this.get('abstandauflager').replace(",", "."));
          var abstandauflagerrounded = abstandauflagerwork.toFixed(1);
          this.set('abstandauflager', abstandauflagerrounded);
          this.send('validation', abstandauflagerrounded, {
            target: {
              name: "abstandauflager"
            }
          });
        }

        if (abstandauflagerAktiv === false) {
          document.getElementsByName('abstandauflager')[0].setSelectionRange(0, this.get('abstandauflager').length);
        }
      },

      lichterabstandIsSelected: function lichterabstandIsSelected() {
        var lichterabstandAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('lichterabstandAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('lichterabstandAktiv', !lichterabstandAktiv);
        if (this.get('lichterabstand') !== "") {
          var lichterabstandwork = parseFloat(this.get('lichterabstand').replace(",", "."));
          var lichterabstandrounded = lichterabstandwork.toFixed(1);
          this.set('lichterabstand', lichterabstandrounded);
          this.send('validation', lichterabstandrounded, {
            target: {
              name: "lichterabstand"
            }
          });
        }

        if (lichterabstandAktiv === false) {
          document.getElementsByName('lichterabstand')[0].setSelectionRange(0, this.get('lichterabstand').length);
        }
      },

      resthoeheobenIsSelected: function resthoeheobenIsSelected() {
        var resthoeheobenAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('resthoeheobenAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('resthoeheobenAktiv', !resthoeheobenAktiv);
        if (this.get('resthoeheoben') !== "") {
          var resthoeheobenwork = parseFloat(this.get('resthoeheoben').replace(",", "."));
          var resthoeheobenrounded = resthoeheobenwork.toFixed(1);
          this.set('resthoeheoben', resthoeheobenrounded);
          this.send('validation', resthoeheobenrounded, {
            target: {
              name: "resthoeheoben"
            }
          });
        }

        if (resthoeheobenAktiv === false) {
          document.getElementsByName('resthoeheoben')[0].setSelectionRange(0, this.get('resthoeheoben').length);
        }
      },

      resthoeheuntenIsSelected: function resthoeheuntenIsSelected() {
        var resthoeheuntenAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('resthoeheuntenAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('resthoeheuntenAktiv', !resthoeheuntenAktiv);
        if (this.get('resthoeheunten') !== "") {
          var resthoeheuntenwork = parseFloat(this.get('resthoeheunten').replace(",", "."));
          var resthoeheuntenrounded = resthoeheuntenwork.toFixed(1);
          this.set('resthoeheunten', resthoeheuntenrounded);
          this.send('validation', resthoeheuntenrounded, {
            target: {
              name: "resthoeheunten"
            }
          });
        }

        if (resthoeheuntenAktiv === false) {
          document.getElementsByName('resthoeheunten')[0].setSelectionRange(0, this.get('resthoeheunten').length);
        }
      },

      durchbruchhoeheIsSelected: function durchbruchhoeheIsSelected() {
        var durchbruchhoeheAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('durchbruchhoeheAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('durchbruchhoeheAktiv', !durchbruchhoeheAktiv);
        if (this.get('durchbruchhoehe') !== "") {
          var durchbruchhoehework = parseFloat(this.get('durchbruchhoehe').replace(",", "."));
          var durchbruchhoeherounded = durchbruchhoehework.toFixed(1);
          this.set('durchbruchhoehe', durchbruchhoeherounded);
          this.send('validation', durchbruchhoeherounded, {
            target: {
              name: "durchbruchhoehe"
            }
          });
        }

        if (durchbruchhoeheAktiv === false) {
          document.getElementsByName('durchbruchhoehe')[0].setSelectionRange(0, this.get('durchbruchhoehe').length);
        }
      },

      durchbruchlaengeIsSelected: function durchbruchlaengeIsSelected() {
        var durchbruchlaengeAktiv = this.controllerFor('application').get('model').x3ddefault.objectAt(0).get('durchbruchlaengeAktiv');
        this.controllerFor('application').get('model').x3ddefault.objectAt(0).set('durchbruchlaengeAktiv', !durchbruchlaengeAktiv);
        if (this.get('durchbruchlaenge') !== "") {
          var durchbruchlaengework = parseFloat(this.get('durchbruchlaenge').replace(",", "."));
          var durchbruchlaengerounded = durchbruchlaengework.toFixed(1);
          this.set('durchbruchlaenge', durchbruchlaengerounded);
          this.send('validation', durchbruchlaengerounded, {
            target: {
              name: "durchbruchlaenge"
            }
          });
        }

        if (durchbruchlaengeAktiv === false) {
          document.getElementsByName('durchbruchlaenge')[0].setSelectionRange(0, this.get('durchbruchlaenge').length);
        }
      }
    }
  });

});